<template lang="html">

    <div class="detail">
      <div class="mainBox">
        <div class="bg" :style="{backgroundColor: bgColor}"></div>
        <div class="main">
          <div class="box" v-if="detail">
            <div class="title">
              <div class="name">
                {{ detail.productName }}
              </div>
              <div class="monery" v-if="detail.order_info">
                ￥{{ detail.order_info.orderPrice }} <span>(已购价格)</span>
              </div>
              <div class="monery" v-else>
                ￥{{ detail.productPrice }}
              </div>
            </div>
            <div class="desc">
              依托于系统的分布式区域管理技术，当营销活动需要集中发布时，总部可实现对全体门店的统一管控，信息高效触达用户； 当活动仅在特殊时段面向特定门店时，总部也能针对指定设备进行精准控制，做到精细化营销。
            </div>

            <div class="btn" v-if="detail.order_info">
              <button type="button">复制登录地址</button>
            </div>

          </div>
          <navlid :list="list"></navlid>
          <div class="context">
            <div v-if="detail" v-html="detail.productText"></div>
          </div>
        </div>

      </div>
      <myBase></myBase>
    </div>

</template>

<script>
import myBase from '@/components/myBase.vue';
import initImg from "../plugin/getImgColor";
export default {
  name: 'detail',
  components: {
    myBase,
  },
  data() {
    return {
      id: '',
      detail: null,
      list: [{title: '个人中心', url: 'personalCenter'}, {title: '产品详细', url: ''}],
      bgColor:"#ffffff"
    };
  },
  created() {
    //do something after creating vue instance
    this.id = this.$route.query.id;
    this.getProductInfo();
    //获取图片某一点颜色 默认（x,y）（20，20）
    initImg(require("../assets/img/detailBk.png")).then((res) => {
      this.bgColor = res;
    })
  },
  methods: {
    getProductInfo() {
      this.$request.HttpGet('/api/productInfo', {id: this.id}).then(res => {
        this.detail = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;

  .mainBox {
    min-height: calc(100vh - 210px);

    min-width: 1200px;
    margin: auto;
    .main{
      position: relative;
      top: -240px;
    }
    .bg{
      width: 100%;
      height: 240px;
    }
  }

  .box {
    height: 240px;
    max-width: 1400px;
    margin: 0 auto;
    background: url('../assets/img/detailBk.png') no-repeat;
    background-size: cover;
    position: relative;
    top: 0;
    .title {
      display: flex;
      align-items: flex-end;
      padding-top: 50px;

      .name {
        font-size: 24px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: bold;
        color: #333333;
        margin-right: 20px;
      }

      .monery {
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: bold;
        color: #CB191A;

        span {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .desc {
      margin-top: 30px;
      width: 640px;
      font-size: 12px;
      color: #666666;
      line-height: 20px;
    }



    .btn {
      button {
        width: 120px;
        height: 30px;
        background: #3D7EFF;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 30px;
        margin-left: 380px;
      }
    }
  }
}
.context {
  width: 100%;
  max-width: 1400px;
  min-width: 1200px;
  margin: 0 auto;

}
</style>

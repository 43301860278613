<template lang="html">
  <div class="myBase" :style="{'background':background,'color':color}">
    <div class="box">
      <div class="left">
        <div class="rowOne MarginBottom15">
          <span>关于我们</span>
          <span>法律声明及隐私政策</span>
          <span>加入我们</span>
        </div>
        <div class="list MarginBottom15">
          <span>牧予生活</span> &nbsp;&nbsp;&nbsp;&nbsp;  <span>华有云</span>
        </div>
        <div class="list">
          ©2021muyu-tech.cn版权所有  &nbsp;&nbsp;  备案号：渝ICP备2020014195号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'myBase',
    props: {
      background: {
        type: String,
        default() {
          return '#464646';
        }
      },
      // color: #333333;
      color:{
        type:String,
        default(){
          return '#C8C8C8'
        }
      }
    },
    data() {
      return {};
    }
  };
</script>

<style lang="scss" scoped>
  .myBase {
     margin-top: auto;
    height: 135px;
    box-sizing: border-box;
    // position: absolute;
    // left: 0;
    // bottom: 0;
    width: 100%;
    min-width: 1200px;
    background-color: #FAFAFA;

    .box {
      width: 100%;
      min-width: 1200px;
      max-width: 1400px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding-top: 30px;
      align-items: flex-end;
      margin: 0 auto;
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.55);
      .left{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .rowOne{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.25);
          span{
            &:nth-of-type(2){
              margin: 0 15px;
            }
          }
        }
      }
    }
  }
</style>

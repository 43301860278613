let pick = (ctx) => {
    let x = 20;
    let y = 20;
    let pixel = (ctx.getImageData(x, y, 1, 1))
    let data = pixel.data;

    const rgba = `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${data[3] / 255})`;
    // destination.style.background = rgba;
    // destination.textContent = rgba;
    return rgba;

}
let initImg = (url) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        let dom = document.createElement('canvas');
        dom.width = 1000; //☜
        dom.height = 500;
        dom.id="canvas"
        dom.style.display = 'none';
        document.body.appendChild(dom)
        img.src = url;
        let canvas = document.getElementById('canvas');
        img.crossOrigin = 'anonymous';
        let ctx = canvas.getContext('2d');
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            resolve(pick(ctx))
            img.style.display = 'none';

        };

    })

}
export default initImg
